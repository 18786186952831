// import { AnchorLink } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from "react"
import logo from '../assets/img/d2i-technology-logo-with-white-bg.png'

const Header = ({siteTitle, hideNav}) => {
  const [navOpen,setNavOpen] = useState(false)
  const [servicesDropdownExpanded,setServicesDropdownExpanded] = useState(false)
  const [testingDropdownExpanded,setTestingDropdownExpanded] = useState(false)
  const [penetrationDropdownExpanded,setPenetrationDropdownExpanded] = useState(false)

  const closeMenu = () => {
    setNavOpen(false)
    setServicesDropdownExpanded(false)
    setTestingDropdownExpanded(false)
  }

  const toggleServices = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setServicesDropdownExpanded(!servicesDropdownExpanded);
    // Close other dropdowns when opening services
    if (!servicesDropdownExpanded) {
      setTestingDropdownExpanded(false);
      setPenetrationDropdownExpanded(false);
    }
  };

  const toggleTesting = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setTestingDropdownExpanded(!testingDropdownExpanded);
    // Close penetration testing dropdown when closing testing
    if (!testingDropdownExpanded) {
      setPenetrationDropdownExpanded(false);
    }
  };

  const togglePenetration = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setPenetrationDropdownExpanded(!penetrationDropdownExpanded);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      if (penetrationDropdownExpanded) {
        // Move focus to the testing toggle button
        const toggleButton = document.querySelector('.toggle-button[aria-controls="pentesting-submenu"]');
        if (toggleButton) {
          setPenetrationDropdownExpanded(false);
          toggleButton.focus();
        }
      } else if (testingDropdownExpanded) {
        // Move focus to the testing toggle button
        const toggleButton = document.querySelector('.toggle-button[aria-controls="testing-submenu"]');
        if (toggleButton) {
          setTestingDropdownExpanded(false);
          setPenetrationDropdownExpanded(false);
          toggleButton.focus();
        }
      } else if (servicesDropdownExpanded) {
        // Move focus to the services toggle button
        const toggleButton = document.querySelector('.toggle-button[aria-controls="services-submenu"]');
        if (toggleButton) {
          setServicesDropdownExpanded(false);
          setTestingDropdownExpanded(false);
          setPenetrationDropdownExpanded(false);
          toggleButton.focus();
        }
      }
    }
  };

  const navMenuRef = useRef(null);

  const handleClickOutside = (e) => {
    if (navMenuRef.current && !navMenuRef.current.contains(e.target)) {
      // Close all dropdowns if clicked outside the nav menu
      setServicesDropdownExpanded(false);
      setTestingDropdownExpanded(false);
      setPenetrationDropdownExpanded(false);
    }
  };

  // Add event listener for keydown
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [servicesDropdownExpanded, testingDropdownExpanded, penetrationDropdownExpanded]);

  // Add event listener for click outside
  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
  <header id="header" className={navOpen ? 'fixed-top mobile-nav-active': 'fixed-top'}>
  <div  className={navOpen ? 'mobile-nav-overly d-block': 'mobile-nav-overly'}  onClick={()=> setNavOpen(false)}></div>
  <div className="container d-flex align-items-center">

    <div className="logo mr-auto">
      <AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/">
      <img id="logoImage" src={logo} alt="D2i Technology - Software development company logo" />
        <div className="d-sm-inline mt-1" aria-hidden="true">{siteTitle}</div>
      </AnchorLink>
    </div>  
    {!hideNav && (<>
    <nav ref={navMenuRef} className="nav-menu d-none d-lg-block" role="navigation" aria-label="Main">
      <ul>
        <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/">Home</AnchorLink></li>
        <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/blogs/">Blogs</AnchorLink></li>
        <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/#about">About</AnchorLink></li>
        <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/#portfolio">Our Products</AnchorLink></li>

        <li className={`drop-down ${servicesDropdownExpanded ? 'active' : ''}`}>
          <div className="menu-item-wrapper">
            <AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/#services">Services</AnchorLink>
            <button
              className="toggle-button"
              aria-expanded={servicesDropdownExpanded}
              aria-controls="services-submenu"
              aria-label="Toggle Services menu"
              onClick={toggleServices}
            >
            </button>
          </div>
          <ul id="services-submenu" className={servicesDropdownExpanded ? 'show' : ''}>
            <li className={`drop-down ${testingDropdownExpanded ? 'active' : ''}`}>
              <div className="menu-item-wrapper">
                <AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/#services" className="menu-link">Testing</AnchorLink>
                <button
                  className="toggle-button"
                  aria-expanded={testingDropdownExpanded}
                  aria-controls="testing-submenu"
                  aria-label="Toggle Testing menu"
                  onClick={toggleTesting}
                >
                </button>
              </div>
              <ul id="testing-submenu" className={testingDropdownExpanded ? 'show' : ''}>
                <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/manual-testing/">Manual Testing</AnchorLink></li>
                <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/automation-testing/">Automation Testing</AnchorLink></li>
                <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/performance-testing/">Performance Testing</AnchorLink></li>
                <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/accessibility-testing/">Accessibility Testing</AnchorLink></li>           
                <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/security-testing/">Security Testing</AnchorLink></li>           

                <li className={`drop-down ${penetrationDropdownExpanded ? 'active' : ''}`}>
                  <div className="menu-item-wrapper">
                    <AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/penetration-testing/" className="menu-link">Penetration Testing</AnchorLink>
                    <button
                      className="toggle-button"
                      aria-expanded={penetrationDropdownExpanded}
                      aria-controls="pentesting-submenu"
                      aria-label="Toggle Penetration Testing menu"
                      onClick={togglePenetration}
                    >
                    </button>
                  </div>
                <ul id="pentesting-submenu" className={penetrationDropdownExpanded ? 'show' : ''}>
                <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/android-penetration-testing/">Android Penetration Testing</AnchorLink></li> 
                <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/api-penetration-testing/">API Penetration Testing</AnchorLink></li> 
                <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/cloud-penetration-testing/">Cloud Penetration Testing </AnchorLink></li> 
                <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/network-penetration-testing/">Network Penetration Testing </AnchorLink></li> 

              </ul></li>
              </ul> </li>
            <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/website-development/">Web Development</AnchorLink></li>
            <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/mobile-development/">Mobile Development</AnchorLink></li>
            <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/accessibility-remediation/">Accessibility Remediation</AnchorLink></li>
            <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/devops/">DevOps</AnchorLink></li>
            <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/secops/">SecOps</AnchorLink></li>
            <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/digital-marketing/">Digital Marketing</AnchorLink></li>
            <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/ai-development/">AI Development</AnchorLink></li>
          </ul>
        </li>


        <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/#clients">Our Clients</AnchorLink></li>
     
       
        {/* <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/teams">Our Team</AnchorLink></li> */}
        <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/careers">Careers</AnchorLink></li>
        {/* <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/teams/user"></AnchorLink></li> */}

      </ul>
    </nav>
    <AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/contact" className="get-started-btn scrollto">Contact Us</AnchorLink>
    <button type="button" className="mobile-nav-toggle d-lg-none" onClick={()=> setNavOpen(!navOpen)} aria-label="Open Navigation"><i className={navOpen ? 'icofont-close': 'icofont-navigation-menu '} aria-hidden="true"></i></button>
    <nav className="mobile-nav d-lg-none" role="navigation" aria-label="Main">
      {/* Div added to provide scroll option in hamburger menu for easy navigation.*/}
    <div style={{ maxHeight: "calc(100vh - 60px)", overflowY: "auto" }}>
      <ul>
        <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/"  onAnchorLinkClick={()=>closeMenu()}>Home</AnchorLink></li>
        <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/blogs/"  onAnchorLinkClick={()=>closeMenu()}>Blogs</AnchorLink></li>
        <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/#about"  onAnchorLinkClick={()=>closeMenu()}>About</AnchorLink></li>
        <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/#portfolio"  stripHash={true} onAnchorLinkClick={()=>closeMenu()}>Our Products</AnchorLink></li>
     
        <li className={servicesDropdownExpanded ? 'active drop-down': 'drop-down'}>
        <div className="menu-item-wrapper">
          <AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/#services"  onAnchorLinkClick={()=>closeMenu()}>Services </AnchorLink>
          <button
              className="toggle-button"
              aria-expanded={servicesDropdownExpanded}
              aria-controls="services-submenu"
              aria-label="Toggle Services menu"
              onClick={toggleServices}
            >
            </button>
        </div>
        {servicesDropdownExpanded && (
              <ul id="services-submenu" className={servicesDropdownExpanded ? 'show' : ''}>
       
              <li className={`drop-down ${testingDropdownExpanded ? 'active' : ''}`}>
                <div className="menu-item-wrapper">
                  <AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/#services" className="menu-link" onAnchorLinkClick={()=>closeMenu()}>Testing</AnchorLink>
                  <button
                    className="toggle-button"
                    aria-expanded={testingDropdownExpanded}
                    aria-controls="testing-submenu"
                    aria-label="Toggle Testing menu"
                    onClick={toggleTesting}
                  >
                  </button>
                </div>
               {testingDropdownExpanded && (
                  <ul id="testing-submenu" className={testingDropdownExpanded ? 'show' : ''}>
                  <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/manual-testing/" onAnchorLinkClick={()=>closeMenu()}>Manual Testing</AnchorLink></li>
                  <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/automation-testing/"  onAnchorLinkClick={()=>closeMenu()}>Automation Testing</AnchorLink></li>
                  <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/performance-testing/"  onAnchorLinkClick={()=>closeMenu()}>Performance Testing</AnchorLink></li>
                  <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/accessibility-testing/"  onAnchorLinkClick={()=>closeMenu()}>Accessibility Testing</AnchorLink></li>           
                  <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/security-testing/"  onAnchorLinkClick={()=>closeMenu()}>Security Testing</AnchorLink></li>           
                  <li className={`drop-down ${penetrationDropdownExpanded ? 'active' : ''}`}>
                    <div className="menu-item-wrapper">
                      <AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/penetration-testing/" className="menu-link" onAnchorLinkClick={()=>closeMenu()}>Penetration Testing</AnchorLink>
                      <button
                        className="toggle-button"
                        aria-expanded={penetrationDropdownExpanded}
                        aria-controls="pentesting-submenu"
                        aria-label="Toggle Penetration Testing menu"
                        onClick={togglePenetration}
                      >
                      </button>
                    </div>
                  {penetrationDropdownExpanded && (
                  <ul id="pentesting-submenu" className={penetrationDropdownExpanded ? 'show' : ''}>
                  <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/android-penetration-testing/"  onAnchorLinkClick={()=>closeMenu()}>Android Penetration Testing</AnchorLink></li> 
                <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/api-penetration-testing/"  onAnchorLinkClick={()=>closeMenu()}>API Penetration Testing</AnchorLink></li> 
                <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/cloud-penetration-testing/"  onAnchorLinkClick={()=>closeMenu()}>Cloud Penetration Testing </AnchorLink></li> 
                <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/network-penetration-testing/"  onAnchorLinkClick={()=>closeMenu()}>Network Penetration Testing </AnchorLink></li> 
                </ul>)}</li>
                </ul>
               )}
                </li>
              <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/website-development/"  onAnchorLinkClick={()=>closeMenu()}>Web Development</AnchorLink></li>
              <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/mobile-development/"  onAnchorLinkClick={()=>closeMenu()}>Mobile Development</AnchorLink></li>
              <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/accessibility-remediation/"  onAnchorLinkClick={()=>closeMenu()}>Accessibility Remediation</AnchorLink></li>
              <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/devops/"  onAnchorLinkClick={()=>closeMenu()}>DevOps</AnchorLink></li>
              <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/secops/"  onAnchorLinkClick={()=>closeMenu()}>SecOps</AnchorLink></li>
              <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/digital-marketing/"  onAnchorLinkClick={()=>closeMenu()}>Digital Marketing</AnchorLink></li>
              <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/ai-development/"  onAnchorLinkClick={()=>closeMenu()}>AI Development</AnchorLink></li>
            </ul>
        )}
        </li>
        <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/#clients"   onAnchorLinkClick={()=>closeMenu()}>Our Clients</AnchorLink></li>
        {/* <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/teams"  onAnchorLinkClick={()=>closeMenu()}>Our Team</AnchorLink></li> */}
        <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/careers"  onAnchorLinkClick={()=>closeMenu()}>Careers</AnchorLink></li>
        {/* <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/teams/user"  onAnchorLinkClick={()=>closeMenu()}></AnchorLink></li> */}

      </ul>
      </div>
    </nav>
    </>)}
  </div>
</header>
)
}
 
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
