import React from 'react';

const SkipToContent = () => {
  const handleSkip = (e) => {
    e.preventDefault();
    // Find the main content area
    const mainContent = document.getElementById('main');
    
    if (mainContent) {
      // Focus on the main content area
      mainContent.focus();
      // Scroll to the main content if needed
      mainContent.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <a 
      href="#main" 
      className="skip-to-content-link" 
      onClick={handleSkip}
    >
      Skip to main content
    </a>
  );
};

export default SkipToContent;